export const schema = {
  "@context": "https://schema.org",
  "@type": "EducationalOrganization",
  "additionalType": [
      "https://schema.org/LocalBusiness",
      "https://schema.org/AdultEducationCenter",
      "https://schema.org/School",
      "https://schema.org/Course",
      "https://schema.org/ProfessionalService",
      "http://www.productontology.org/id/Tutoring",
      "http://www.productontology.org/id/Math_education",
      "http://www.productontology.org/id/Physics_Tutor",
      "http://www.productontology.org/id/Math_Tutor"
  ],
  "name": "Dija Academy",
  "description": "Dija Academy offers a comprehensive range of high-quality tutoring services, including math tutoring, science tutoring, physics tutoring, and programming courses in Zurich and Dübendorf.",
  "url": "https://www.dijaacademy.ch",
  "telephone": "+4748240032",
  "location": [
      {
          "@type": "Place",
          "name": "Dija Academy - Zurich",
          "telephone": "+4748240032",
          "address": {
              "@type": "PostalAddress",
              "streetAddress": "Heerenwiesen 20",
              "addressLocality": "8051 Zürich",
              "addressCountry": "Switzerland"
          }
      },
      {
          "@type": "Place",
          "name": "Dija Academy - Dübendorf",
          "telephone": "+4748240032",
          "address": {
              "@type": "PostalAddress",
              "streetAddress": "Heerenwiesen 20",
              "addressLocality": "Dübendorf",
              "addressCountry": "Switzerland"
          }
      },
      {
          "@type": "Place",
          "name": "Dija Academy - Oslo",
          "telephone": "+4748240032",
          "address": {
              "@type": "PostalAddress",
              "streetAddress": "Eilert Sundts Gate 11A",
              "addressLocality": "Oslo",
              "addressCountry": "Norway"
          }
      }
  ],
  "brand": {
      "@type": "Brand",
      "name": "Dija Academy",
      "description": "Dija Academy is a trusted name in personalized tutoring and educational services."
  },
  "course": [
      {
          "@type": "Course",
          "name": "Cambridge IGCSE Tutoring",
          "description": "Specialized tutoring for Cambridge IGCSE subjects.",
          "provider": "Dija Academy"
      },
      {
          "@type": "Course",
          "name": "IB Tutoring",
          "description": "Expert tutoring services for International Baccalaureate subjects.",
          "provider": "Dija Academy"
      },
      {
          "@type": "Course",
          "name": "Math Tutoring",
          "description": "One-on-one and group tutoring sessions for algebra, geometry, and calculus.",
          "provider": "Dija Academy"
      },
      {
          "@type": "Course",
          "name": "Programming Courses",
          "description": "Learn Python, Java, and C++ with our expert programming courses.",
          "provider": "Dija Academy"
      },
      {
          "@type": "Course",
          "name": "Numerical Analysis Tutoring",
          "description": "Comprehensive tutoring in Numerical Analysis and specialized Data Science courses.",
          "provider": "Dija Academy"
      }
  ],
  "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.7",
      "reviewCount": "150"
  }
};

export const metaTags = [
  {
      name: "description",
      content: "Dija Academy offers high-quality tutoring services, including math tutoring, science tutoring, and programming courses in Dübendorf and Zurich."
  },
  {
      name: "keywords",
      content: "Cambridge, IGCSE, IB tutoring, math tutor, physics tutor, private tutor, programming courses, Zurich, Dübendorf"
  }
];

export const openGraphTags = [
  { property: "og:title", content: "Dija Academy - High-Quality Tutoring Services" },
  { property: "og:description", content: "Dija Academy offers high-quality tutoring services, including math tutoring, science tutoring, and programming courses in Dübendorf and Zurich." },
  { property: "og:type", content: "website" },
  { property: "og:url", content: "https://www.dijaacademy.ch" },
  { property: "og:image", content: "https://www.dijaacademy.ch/assets/logo.png" },
  { property: "og:locale", content: "en_CH" },
  { property: "og:site_name", content: "Dija Academy" }
];
