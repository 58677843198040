import React from 'react'
import { Helmet } from 'react-helmet';
import { schema, metaTags, openGraphTags } from '../SEOConfig';
export default function Hero() {

    

    return (
        <div>
                  <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schema)}
        </script>

        {/* Standard Meta Tags */}
        <title>Dija Academy - High-Quality Educational, Tutoring Services, Math, Science, Physics, Cambridge, IGCSE, IB, American</title>
        {metaTags.map((tag, index) => <meta key={index} name={tag.name} content={tag.content} />)}
      {openGraphTags.map((tag, index) => <meta key={index} property={tag.property} content={tag.content} />)}

      </Helmet>
            <section id="hero" className="d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay={200}>
                        <h1>Dija is an Educaitonal service, learning incubator and accelerator for learners at all ages and skill levels </h1>
                            <h2>Led by highly pedagogic lecturer with teaching experience at top universities Like ETH Zurich.</h2>
                            <h2>
                            With multidisciplinary and cross functional background (Engineering, Applied Mathematics, Physics, and more),
                                We help students progress, get the right spoting, feel challenged enough and enjoy their learning experience.
                            </h2>

                            <div className="d-flex justify-content-center justify-content-lg-start">
                                <a href="#contact" className="btn-get-started scrollto">Get Started</a>
                                {/*                                 <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="glightbox btn-watch-video"><i className="bi bi-play-circle" /><span>Watch Video</span></a>
*/}
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay={200}>
                            <img src="assets/img/Math_Physics_tutorials.png" className="img-fluid animated" style={{borderRadius:"10%"}} alt />
                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
}
