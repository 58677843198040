import React from 'react'

export default function AboutUs() {
    return (
        <div>
            <section id="about" className="about">
                <div className="container" data-aos="fade-up">
                <div className="section-title">
    <h2>About Us</h2>
</div>
<div>
    <h3>Dija: Your Dedicated Learning Partner</h3>
    <p style={{fontStyle: "italic"}}>
        With offering in both in Zurich and Dübendorf, Dija is a premier learning incubator and accelerator. We're committed to supporting learners of all ages and skill levels, from kindergarteners to M.Sc. graduate students. Whether you're self-studying, seeking to understand challenging topics, or wanting to excel further in your studies, Dija is here for you.
    </p>
    
    <p>
        Our tutoring school builds strong partnerships with both learners and parents. This allows us to tailor our teaching methods to match individual or group learning objectives and styles. Whether you feel you're lagging behind, want to reinforce your learning, or are seeking more advanced challenges, Dija has a solution tailored for you.
    </p>
    
    <h4>Our Offerings:</h4>
    <ul>
        <li>Full and block crash courses.</li>
        <li>Exercise and problem-solving mastery sessions.</li>
        <li>Customizable sessions available online, or at a location convenient for the student.</li>
        <li>Both individual and group sessions to cater to various learning needs.</li>
    </ul>

    <h4>Focus Areas:</h4>
    <ul>
        <li>Mathematics: Calculus, Algebra, Trigonometry, Numerical Analysis, Statistics, and more.</li>
        <li>Physics</li>
        <li>Artificial Intelligence/Machine Learning (AI/ML)</li>
        <li>IT and Software Development</li>
    </ul>

{/*     <p>
        While our expertise lies in the areas listed above, we are also flexible in accommodating requests for subjects outside our main focus. Please note, however, that these may require additional arrangements and may be subject to availability, especially for group classes.
    </p> */}
</div>
<div className="section-title">
    <h3>Founder's Philosophy and Pedagogic Approach</h3>
</div>
<div className="row content">
    <div className="col-lg-12">
        <h5>
            Mathematics and physics are not just subjects that students need to master to enroll in science or engineering programs; they are much more than that. I believe these subjects equip learners with essential skills and abilities akin to fundamental senses like vision and hearing. They provide a framework for reasoning, logic, and awareness that empowers individuals to navigate through life rationally and knowledgeably. Regardless of one's future ambitions or specializations, these subjects offer invaluable life skills.
        </h5>

        <p>
            Unfortunately, a disproportionately large number of people believe they aren't suited for mathematics or physics. While not everyone can be a math genius or win the Fields Medal, my experience suggests that most people, even those who lack confidence in their abilities, can understand and excel in these subjects at the school, undergraduate, and graduate levels. It's similar to sports; while not everyone can compete in the Olympics, most people can engage in physical activities like running or swimming and reap the health and social benefits. A lack of innate talent should not deter us from achieving proficiency and enjoying the learning process.
        </p>

        <p>
            To formalize this discussion, let's consider that the Intelligence Quotient (IQ) distribution of learners follows a Gaussian distribution, as illustrated below:
        </p>

        <img src="assets/img/Gaussian.png" 
             style={{
                 borderRadius:"10%",
                 marginRight: "auto",
                 marginLeft: "auto",
                 marginBottom: "20px",
                 display: 'block'}} alt="Gaussian Distribution"/>

        <p>
            A simplistic interpretation of this Gaussian distribution is that, in a given population taking the IQ test, the group scoring around 100 will be the largest (since IQ tests are designed to have an average score of 100). As we move towards the tails of the distribution, the groups become smaller. Assuming a Gaussian distribution, roughly 84% of the population will score 85 or higher on an IQ test. Based on my experience, this segment can attain a solid understanding of mathematics and physics and benefit substantially from learning these subjects.
        </p>

        <p>
            The traditional school system primarily targets average learners, those scoring close to 100 on the IQ test. As a result, students scoring between 85 and 100 may need to put in extra effort and risk falling behind, while those scoring above 115 might feel unchallenged and fail to realize their full potential. However, with the right pedagogic approach and adequate effort, most individuals can master these essential subjects.
        </p>


<div className="section-title">
    <h3>Mission</h3>
</div>
<div className="row content">
    <div className="col-lg-12">
        <p>
            At Dija, our mission is more than just academic success; it's about fostering a lifelong passion for learning. We aim to provide a comprehensive, inclusive, and engaging learning environment, enabling learners of all backgrounds and capabilities to understand and navigate the world through a scientific, evidence-based lens. Furthermore, we're committed to extending the gift of education beyond our walls. Through our collaborations with charity organizations, we're proud to support learning and nutrition initiatives in underserved communities worldwide.
        </p>
    </div>
</div>


<div className="section-title">
    <h3>Vision</h3>
</div>
<div className="row content">
    <div className="col-lg-12">
        <p>
            We envision a world where every individual, regardless of their background, has the opportunity to unlock their full potential through quality education. By combining rigorous academic instruction with a nurturing environment, we hope to inspire the next generation to be curious, compassionate, and informed global citizens.
        </p>
    </div>
</div>


    </div>
</div>


                 
                  </div>

            </section>
        </div>
    )
}
