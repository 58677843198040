import React from 'react';

export default function GetStarted() {
    return (
        <div>
            <section id="contact" className="contact">
                <div className="container" data-aos="fade-up">
                    <div className="section-title">
                        <h2>Get Started</h2>
                    </div>
                    <div className="row content" style={{ "text-align": "center" }}>
                        <div className="col-lg-12">
                            <ul>
                                <li>
                                    Interested in enrolling or learning more about Dija Academy? We invite you to contact us. Our dedicated team will be happy to assist you in understanding our courses, methodology, and what sets us apart.
                                </li>
                                <li>
                                    Upon receiving your inquiry, we'll arrange a one-on-one session to discuss your or your child's academic needs and aspirations. This helps us tailor our approach to individual learning styles and goals.
                                </li>
                                <li>
                                    Following our initial conversation, we'll guide you through the enrollment process, ensuring it's smooth and straightforward.
                                </li>
                                <li>
                                    For parents or students keen on specific subjects outside of Math and Physics, do mention this in your correspondence. We strive to accommodate and cater to a broad range of academic interests.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 d-flex align-items-stretch">
                            <div className="info">
                                <div className="address">
                                    <i className="bi bi-geo-alt" />
                                    <h4>Location:</h4>
                                    <p>Heerenwiesen 20, 8051 Zürich</p>
                                </div>
                                <div className="email">
                                    <i className="bi bi-envelope" />
                                    <h4>Email:</h4>
                                    <p>drmo@dijaacademy.ch</p> {/* Adjust this to your academy's email */}
                                </div>
                                <div className="phone">
                                    <i className="bi bi-phone" />
                                    <h4>Call:</h4>
                                    <p>Please reach out via email, and we'll get back to you promptly.</p>
                                </div>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5400.674507946554!2d8.574473899999997!3d47.4053626!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479aa0604494cc69%3A0xb36e7a288f1ae67f!2sHeerenwiesen%2020%2C%208051%20Z%C3%BCrich!5e0!3m2!1sen!2sch!4v1733678684669!5m2!1sen!2sch"
                                    frameBorder={0}
                                    style={{ border: 0, width: '100%', height: 290 }}
                                    allowFullScreen
                                    />
                              </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
